.container-bg {
  position: relative;
  .container-img {
    position: absolute;
    width: 90px;
  }
}
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}
.m-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

