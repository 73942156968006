.mint {
    padding-top: 112px;
    width: 80%;
    margin: 0 auto;
}


.title-container {
    width: 1280px;
    position: relative;
    height: 40px;
    padding-left: 100px;
}
.m-title-container {
    position: relative;
    height: 40px;
    padding-left: 10%;
}

.nft-images-container {
    padding-left: 15%;
    padding-right: 15%;
    margin-top: 40px;
}

.m-nft-images-container {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 30px;
}

.buy-btn-container {
    display: flex;
    justify-content: center;
    padding: 60px 0;
    .buy-btn {
        background: linear-gradient(to right, #39fe7b, #0f6151);
        font-size: 30px;
        font-weight: 700;
        color: #FFF;
        padding: 10px;
        border-radius: 15px;
    }
}

.m-buy-btn-container {
    display: flex;
    justify-content: center;
    padding: 35px 0;
    .buy-btn {
        background: linear-gradient(to right, #39fe7b, #0f6151);
        font-size: 21px;
        font-weight: 700;
        color: #FFF;
        padding: 10px;
        border-radius: 15px;
    }
}