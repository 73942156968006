
.introduce-container {
    height: fit-content;
    margin-top: 150px;
    display: flex;
    justify-content: center;
    .introduce-content {
        height: 100%;
        width: 1280px;
        .title-container {
            position: relative;
            height: 40px;
            padding-left: 100px;
        }
        .nft-images-container {
            padding-left: 15%;
            padding-right: 15%;
            margin-top: 40px;
        }
        .nft-introduce {
            padding-left: 15%;
            padding-right: 15%;
            margin-top: 60px;
        }
    }
}
.m-introduce-container {
    height: fit-content;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    .introduce-content {
        height: 100%;
        .title-container {
            position: relative;
            height: 40px;
            padding-left: 10%;
        }
        .nft-images-container {
            padding-left: 10%;
            padding-right: 10%;
            margin-top: 40px;
        }
        .nft-introduce {
            padding-left: 10%;
            padding-right: 10%;
            margin-top: 50px;
        }
    }
}