.banner-wrapper {
    padding-top: 150px;
    max-width: 1280px;
    position: relative;
    .mint-apes {
        position: absolute;
        left: 50%;
        bottom: -30px;
        width: 350px;
        height: 70px;
        margin-left: -175px;
        .mint-apes-button {
            width: 350px;
            height: 70px;
            font-size: 71px;
            font-weight: 700;
            color: #FFF;
            border-radius: 20px;
        }
    }
}
.m-banner-wrapper {
    position: relative;
    .mint-apes {
        position: absolute;
        left: 50%;
        bottom: -20px;
        width: 120px;
        height: 40px;
        margin-left: -60px;
        .mint-apes-button {
            width: 120px;
            height: 40px;
            font-size: 71px;
            font-weight: 700;
            color: #FFF;
            border-radius: 20px;
        }
    }
}



@keyframes sold-animation {
    0% {
        transform: scale(1.2);
    }
    25% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    75% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}

@media (prefers-reduced-motion: no-preference) {
    .mint-apes-button {
      animation: sold-animation 2s infinite;
    }
}
    