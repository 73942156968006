.mynft {
    padding-top: 112px;
    width: 80%;
    margin: 0 auto;
    height: 100%;
    width: 1280px;
    padding-top: 120px;
    .title {
        color: #FFF;
        font-weight: 700;
        padding-top: 100px;
    }
    .tabs {
        color: "#FFF";
         & .MuiTabs-indicator {
          background-color: #fff;
        }
        & .MuiButtonBase-root {
        //   paddingLeft: 0;
        }
    }
}

.title-container {
    position: relative;
    height: 40px;
    padding-left: 100px;
    margin-top: 200px;
    padding-bottom: 30px,
}

.m-title-container {
    position: relative;
    height: 40px;
    margin-top: 80px;
    padding-left: 10%;
}

.m-mynft {
    .title {
        color: #FFF;
        font-weight: 700;
        padding-top: 20px;
    }
    .tabs {
        color: "#FFF";
         & .MuiTabs-indicator {
          background-color: #fff;
        }
        & .MuiButtonBase-root {
        //   paddingLeft: 0;
        }
    }
}