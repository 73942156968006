
.introduce-container {
    height: fit-content;
    padding-top: 350px;
    display: flex;
    justify-content: center;
    .introduce-content {
        height: 100%;
        width: 1280px;
        .title-container {
            position: relative;
            height: 40px;
            padding-left: 100px;
        }
        .token-desc-container {
            padding-left: 15%;
            padding-right: 15%;
        }
    }
}

.m-introduce-container {
    height: fit-content;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    .introduce-content {
        height: 100%;
        .title-container {
            position: relative;
            height: 40px;
            padding-left: 10%;
        }
        .token-desc-container {
            padding-left: 10%;
            padding-right: 10%;
        }
    }
}