
.introduce-container {
    height: fit-content;
    margin-top: 150px;
    display: flex;
    justify-content: center;
    .introduce-content {
        height: 100%;
        width: 1280px;
        .title-container {
            position: relative;
            height: 40px;
            padding-left: 100px;
        }
        .token-desc-container {
            margin-top: 150px;
            height: 450px;
            background-color: #0d7133;
            position: relative;
            .token-cash-img {
                position: absolute;
                width: 290px;
                left: 15%;
                top: -145px;
            }
            .token-desc-content {
                position: absolute;
                left: 15%;
                right: 15%;
                top: 180px;
                font-size: 23px;
                line-height: 40px;
                color: #FFF;
            }
            .token-section-img {
                position: absolute;
                width: 670px;
                bottom: 0;
                right: 0;
            }
        }
    }
}

.m-introduce-container {
    height: fit-content;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    .introduce-content {
        height: 100%;
        width: 100%;
        .title-container {
            position: relative;
            height: 40px;
            padding-left: 100px;
        }

        .title-container {
            position: relative;
            height: 40px;
            padding-left: 10%;
        }
        .token-desc-container {
            margin-top: 60px;
            height: 700px;
            background-color: #0d7133;
            position: relative;
            .token-cash-img {
                position: absolute;
                width: 90px;
                left: 10%;
                top: -45px;
            }
            .token-desc-content {
                position: absolute;
                left: 10%;
                right: 10%;
                top: 80px;
                font-size: 21px;
                line-height: 40px;
                color: #FFF;
            }
            .token-section-img {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
}