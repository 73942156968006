.Header {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 120px;
    background-color: #000;
    .header-content {
        z-index: 1;
        width: 1280px;
        height: 120px;
        max-width: 100%;
        padding-bottom: 30px;
    }
    .header-nav {
        height: 120px;
        .nav-button {
            color: #FFF;
            font-size: 17px;
            font-weight: 700;
        }
    }
}